<template>
    <div>
        <div class="text-center">
            <h2>Evangelos Giannothanasis</h2>
        </div>
        <v-container class="main-text text-container">
            <div>
                <v-img :width="415" :height="415" style="float: left;" class="bradius" :src="require('@/assets/partners/Photo-E-Giannothanasis.jpg')" />
                <p class="cv-text">
                    Evangelos Giannothanasis is an agronomist, graduate of the Department of Crop Science of the Agricultural University of Athens. He is PhD candidate at the Laboratory of Vegetables Production, Agricultural University of Athens, working on the optimization of nutrition in greenhouse crops with emphasis on soilless culture systems. He participates in the European project ECONUTRI, HORIZON EUROPE as a researcher focusing on the development of the innovative decision support system NUTRISENSE for nutrient management in closed-loop soilless culture systems. He also participates in innovation implementation projects of Submeasure 16.1-16.2 of the Ministry of Rural Development and Food. His main research interests include issues related to the management of plant nutrition and irrigation of vegetables grown both soilless and in soil, the equipment of soilless culture systems, as well as the study-management of abiotic stresses in such systems. His authorship includes 2 papers published in peer-reviewed international journals, 3 scientific papers in full proceedings of international conferences, and 3 scientific papers in national conferences.
                </p>
            </div>
        </v-container>
    </div>
</template>

<script>
    export default {
        name: 'App',
        components: {
        },
        data: () => ({
        }),
        computed: {
        }
    }
</script>
<style scoped>
.cv-card {
    border: 1px solid #D9D9D9;
    border-radius: 25px;
    text-align: center;
}
.bradius {
    border-radius: 25px;
    margin-right: 15px;
}
.cv-text {
    display: inline;
}
.main-text {
    width: 60vw;
    margin: auto;
    color: #646464;
}
</style>
